import "react-toastify/dist/ReactToastify.css";

const Msg = ({ closeToast, toastProps, message }) => {
    
    return (
        <div>
            {message}
        </div>
    );
}

export default Msg;