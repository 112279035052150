import { Link, useLocation } from 'react-router-dom';

import './MainNavigation.scss';
import logo from '../assets/images/bps-logo.svg';

const MainNavigation = () => {
    let location = useLocation();
	

    return (
        <>
			<header>
				<Link to='/createpdf'><img src={logo} alt='Home page' style={{width: '6.5rem'}}/></Link>
				<nav>
					<Link to='/createpdf' className={`${location.pathname==='/createpdf' ? 'item-current':''}`}>Create PDF file</Link>
				</nav>
				
			</header>
		</>
    );
}

export default MainNavigation;