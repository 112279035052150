import { useState, useRef, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
	Box, FormGroup, FormControl, TextField, Select, MenuItem, InputLabel, Button, Radio, RadioGroup, Checkbox, FormControlLabel, FormLabel, Autocomplete, Alert, List, ListItem
} from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import countries from '../countries';

import MainNavigation from "../components/MainNavigation";
import displayMsg from "../components/displayMsgFunc";
import { ToastContainer} from 'react-toastify';
import './PDFCreationForm.scss';

const PDFCreationForm = () => {
    const navigate = useNavigate();
    localStorage.removeItem('pdfData');
    const [phone, setPhone] = useState('');
	const handleChange = (newPhone) => {
		setPhone(newPhone);
	}
	const [editorNameValue, setEditorNameValue] = useState('');
	const [offerForValue, setOfferForValue] = useState('');
	const [customerTitleValue, setCustomerTitleValue] = useState('');
	const [state, setState] = useState({
		boxXXLBPSCheckbox: false,
		boxLargeBPSCheckbox: false,
		boxMidLargeBPSCheckBox: false,
		boxMediumBPSCheckBox: false,
		boxSmallBPSCheckBox: false,
		anotherPickDiffCheckBox: false,
		anotherPickSameCheckBox: false,
		pickOnOrderCheckBox: false,
		returnProcessingPerItemCheckBox: false,
		returnProcessingCheckBox: false,
		assemblyCheckBox: false
	});
	const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
		setState({
			...state,
			[event.target.name]: event.target.checked
		});
	};
	const { boxXXLBPSCheckbox, boxLargeBPSCheckbox, boxMidLargeBPSCheckBox, boxMediumBPSCheckBox, boxSmallBPSCheckBox, anotherPickDiffCheckBox, anotherPickSameCheckBox, pickOnOrderCheckBox, returnProcessingPerItemCheckBox, returnProcessingCheckBox, assemblyCheckBox } = state;


    /* BEGIN declaration of fields refs */
	//const editorNameRef = useRef();
	const editorPhoneRef = useRef();
	const editorEmailRef = useRef();
	//const customerTitleRef = useRef();
	const customerContactPersonRef = useRef();
	const customerCompanyRef = useRef();
	const customerStreetRef = useRef();
	const customerStrNumberRef = useRef();
	const customerZipRef = useRef();
	const customerCityRef = useRef();
	const customerCountryRef = useRef();
	//const offerForRef = useRef();
	const skuNumberRef = useRef();
	const shippingOrdersRef = useRef();
	const reqStorageSpaceRef = useRef();
	const erpConnectionRef = useRef();
	const goodsReceiptProPaletteRef = useRef();
	const goodsReceiptProPackageRef = useRef();
	const dischargeAndStorageRef = useRef();
	const otherServicesRef = useRef();
	const otherServiceRef = useRef();
	const unannouncedDeliveryRef = useRef();
	const assemblyCheckRef = useRef();
	const assemblyInputRef = useRef();
	const returnProcessingCheckRef = useRef();
	const returnProcessingInputRef = useRef();
	const returnProcessingPerItemCheckRef = useRef();
	const returnProcessingPerItemInputRef = useRef();
	const pickOnOrderCheckRef = useRef();
	const pickOnOrderInputRef = useRef();
	const anotherPickSameCheckRef = useRef();
	const anotherPickSameInputRef = useRef();
	const anotherPickDiffCheckRef = useRef();
	const anotherPickDiffInputRef = useRef();
	const boxSmallBPSCheckRef = useRef();
	const boxSmallBPSInputRef = useRef();
	const boxMediumBPSCheckRef = useRef();
	const boxMediumBPSInputRef = useRef();
	const boxMidLargeBPSCheckRef = useRef();
	const boxMidLargeBPSInputRef = useRef();
	const boxLargeBPSCheckRef = useRef();
	const boxLargeBPSInputRef = useRef();
	const boxXXLBPSCheckRef = useRef();
	const boxXXLBPSInputRef = useRef();
 	/* END declaration of fields refs */
    
    const submitHandler = (event) => {
        event.preventDefault();	

        const editorName = editorNameValue;
		const editorPhone = editorPhoneRef.current.value;
		const editorEmail = editorEmailRef.current.value;
		const customerTitle = customerTitleValue;
		const customerContactPerson = customerContactPersonRef.current.value;
		const customerCompany = customerCompanyRef.current.value;
		const customerStreet = customerStreetRef.current.value;
		const customerStrNumber = customerStrNumberRef.current.value;
		const customerZip = customerZipRef.current.value;
		const customerCity = customerCityRef.current.value;
		const customerCountry = customerCountryRef.current.value;
		const offerFor = offerForValue;
		const skuNumber = skuNumberRef.current.value;
		const shippingOrders = shippingOrdersRef.current.value;
		const reqStorageSpace = reqStorageSpaceRef.current.value;
		const erpConnection = erpConnectionRef.current.value;
		const goodsReceiptProPalette = goodsReceiptProPaletteRef.current.value;
		const goodsReceiptProPackage = goodsReceiptProPackageRef.current.value;
		const dischargeAndStorage = dischargeAndStorageRef.current.value;
		const otherServices = otherServicesRef.current.value;
		const otherService = otherServiceRef.current.value;
		const unannouncedDelivery = unannouncedDeliveryRef.current.value;
		const assemblyCheck = assemblyCheckBox;
		const assemblyInput = assemblyInputRef.current.value;
		const returnProcessingCheck = returnProcessingCheckBox;
		const returnProcessingInput = returnProcessingInputRef.current.value;
		const returnProcessingPerItemCheck = returnProcessingPerItemCheckBox;
		const returnProcessingPerItemInput = returnProcessingPerItemInputRef.current.value;
		const pickOnOrderCheck = pickOnOrderCheckBox;
		const pickOnOrderInput = pickOnOrderInputRef.current.value;
		const anotherPickSameCheck = anotherPickSameCheckBox;
		const anotherPickSameInput = anotherPickSameInputRef.current.value;
		const anotherPickDiffCheck = anotherPickDiffCheckBox;
		const anotherPickDiffInput = anotherPickDiffInputRef.current.value;
		const boxSmallBPSCheck = boxSmallBPSCheckBox;
		const boxSmallBPSInput = boxSmallBPSInputRef.current.value;
		const boxMediumBPSCheck = boxMediumBPSCheckBox;
		const boxMediumBPSInput = boxMediumBPSInputRef.current.value;
		const boxMidLargeBPSCheck = boxMidLargeBPSCheckBox;
		const boxMidLargeBPSInput = boxMidLargeBPSInputRef.current.value;
		const boxLargeBPSCheck = boxLargeBPSCheckbox;
		const boxLargeBPSInput = boxLargeBPSInputRef.current.value;
		const boxXXLBPSCheck = boxXXLBPSCheckbox;
		const boxXXLBPSInput = boxXXLBPSInputRef.current.value;

		const formData = {
			editorName,
			editorPhone,
			editorEmail,
			customerTitle,
			customerContactPerson,
			customerCompany,
			customerStreet,
			customerStrNumber,
			customerZip,
			customerCity,
			customerCountry,
			offerFor,
			skuNumber,
			shippingOrders,
			reqStorageSpace,
			erpConnection,
			goodsReceiptProPalette,
			goodsReceiptProPackage,
			dischargeAndStorage,
			otherServices,
			otherService,
			unannouncedDelivery,
			assemblyCheck,
			assemblyInput,
			returnProcessingCheck,
			returnProcessingInput,
			returnProcessingPerItemCheck,
			returnProcessingPerItemInput,
			pickOnOrderCheck,
			pickOnOrderInput,
			anotherPickSameCheck,
			anotherPickSameInput,
			anotherPickDiffCheck,
			anotherPickDiffInput,
			boxSmallBPSCheck,
			boxSmallBPSInput,
			boxMediumBPSCheck,
			boxMediumBPSInput,
			boxMidLargeBPSCheck,
			boxMidLargeBPSInput,
			boxLargeBPSCheck,
			boxLargeBPSInput,
			boxXXLBPSCheck,
			boxXXLBPSInput
		}

		if(editorName.length > 0 && customerTitle.length > 0 && customerCountry.length > 0) {
			localStorage.setItem("pdfData", JSON.stringify(formData));
			navigate('/pdfpreview');
		} else {
			displayMsg({type:'error', message: 'Please fill all mandatory fields.'});
		}
    }

    return (
        <>
			<MainNavigation/>
			<ToastContainer/>
			<main>
				<h2>PDF Creation Form</h2>
				<section className="mainContent pdf-creation-form">
				<Box
					component="form" 
					sx={{
						display: 'flex',
						flexFlow: 'row',
						flexWrap: 'wrap',
						justifyElements: 'space-between',
						width: '920px',
						'& > :not(style)' : { }
					}} 
					autoComplete="off"
				>
					<div style={{width: '100%', margin: 'auto'}}>
						<span className='txt-form-group-headline'>Bearbeiter details:</span>
					</div>
					<FormGroup>
						
						<FormControl fullWidth>
							<InputLabel required id="bearbeiter">Bearbeiter</InputLabel>
							<Select 
								required
								labelId="bearbeiter-label" 
								id="bearbeiter-menu" 
								label="Bearbeiter"
								value={editorNameValue}
								onChange={(e)=>{setEditorNameValue(e.target.value)}}
							>
								<MenuItem value="Kevin Yeingst">Kevin Yeingst</MenuItem>
								<MenuItem value="Radu Paiusan">Radu Paiusan</MenuItem>
								<MenuItem value="Marco Dimaggio">Marco Dimaggio</MenuItem>
								<MenuItem value="Annemarie Narat">Annemarie Narat</MenuItem>
							</Select>
						</FormControl>
					</FormGroup>
					<FormGroup>
						<MuiTelInput value={phone} onChange={handleChange} label="Telefon" inputRef={editorPhoneRef} />
					</FormGroup>
					<FormGroup>
						<TextField id="bearbeiter-email" label="E-Mail" variant="outlined" inputRef={editorEmailRef}/>
					</FormGroup>
					<div style={{width: '100%', margin: '2rem 1rem auto auto'}}>
						<hr/>
						<span className='txt-form-group-headline' style={{paddingTop: '1rem', display: 'block'}}>Kunde details:</span>
					</div>
					<FormGroup>
						<FormControl>
							<InputLabel required id="title">Title</InputLabel>
							<Select 
								required 
								labelId="title-label" 
								label="Title" 
								id="title-menu" 
								value={customerTitleValue} 
								onChange={(e)=>{setCustomerTitleValue(e.target.value)}}
							>
								<MenuItem value="Herr">Herr</MenuItem>
								<MenuItem value="Frau">Frau</MenuItem>
							</Select>
						</FormControl>
					</FormGroup>
					<FormGroup>
						<TextField id="kunde-contact-person" label="Ansprechpartner" variant="outlined" inputRef={customerContactPersonRef} />
					</FormGroup>
					<FormGroup>
						<TextField id="kunde-company" label="Firmenname" variant="outlined" inputRef={customerCompanyRef} />
					</FormGroup>
					<div style={{width: '100%', margin: '1rem auto auto'}}>
						<span className='txt-highlighted-text'>Anschrift:</span>
					</div>
					<FormGroup>
						<TextField id="kunde-street" label="Street" variant="outlined" inputRef={customerStreetRef}/>
					</FormGroup>
					<FormGroup>
						<TextField id="kunde-street-number" label="Number" variant="outlined" inputRef={customerStrNumberRef} />
					</FormGroup>
					<FormGroup>
						<TextField id="kunde-zip" label="ZIP Code" variant="outlined" inputRef={customerZipRef} />
					</FormGroup>
					<FormGroup>
						<TextField id="kunde-city" label="City" variant="outlined" inputRef={customerCityRef} />
					</FormGroup>
					<Autocomplete 
						id="kunde-country" 
						options={countries} 
						autoHighlight 
						getOptionLabel={(option) => option.label} 
						renderOption={(props, option) => (
							<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
								<img 
									loading="lazy" 
									width="20" 
									src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} 
									srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`} 
									alt=""
								/>
								{option.label} ({option.code})
							</Box>
						)}
						renderInput={(params) => (
							<TextField 
								{...params} 
								label="Choose a country" 
								required
								inputProps={{
									...params.inputProps, 
									autoComplete: 'new-password', // disable autocomplete and autofill
								}}
								inputRef={customerCountryRef} 
							/>
						)}
					/>
					<FormControl style={{width: '100%'}}>
						<FormLabel id="service-label">Angebot erstellen für</FormLabel>
						  <RadioGroup
							row
							aria-labelledby="service-label"
							name="row-radio-buttons-group" 
							value={offerForValue} 
							onChange={(e)=>{setOfferForValue(e.target.value)}}
						  >
							<FormControlLabel value="Fulfillment" control={<Radio />} label="Fulfillment" />
							<FormControlLabel value="Versand" control={<Radio />} label="Versand" />
							<FormControlLabel value="Partner" control={<Radio />} label="Partner" />
						  </RadioGroup>
					</FormControl>
					<FormGroup>
						<TextField id="sku" label="Anzahl SKU" variant="outlined" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} inputRef={skuNumberRef}/>
					</FormGroup>
					<FormGroup>
						<TextField id="dispatch-orders" label="Versandaufträge" variant="outlined" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} helperText='monatl.' inputRef={shippingOrdersRef} />
					</FormGroup>
					<FormGroup>
						<TextField id="req-storage-space" label="Benötigte Lagerfläche" variant="outlined" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} helperText='m³' inputRef={reqStorageSpaceRef} />
					</FormGroup>
					<FormGroup>
						<TextField id="erp-shop-connection" label="Anbindung" variant="outlined" helperText='ERP-/Shopsystem' inputRef={erpConnectionRef} />
					</FormGroup>
					<FormGroup>
						<TextField id="goods-receipt-palette" label="Warenannahme" variant="outlined" helperText='pro Palette' inputRef={goodsReceiptProPaletteRef} />
					</FormGroup>
					<FormGroup>
						<TextField id="goods-receipt-paket" label="Warenannahme" variant="outlined" helperText='pro Paket' inputRef={goodsReceiptProPackageRef} />
					</FormGroup>
					<FormGroup>
						<TextField id="unloading-and-storage" label="Entladung +  EInlagerung" variant="outlined" helperText='pro Person & Stunde' inputRef={dischargeAndStorageRef} />
					</FormGroup>
					<FormGroup>
						<TextField id="services-per-warehouse" label="Sonstige Dienstleistungen" variant="outlined" helperText='pro Lagerist & Stunde' inputRef={otherServicesRef} />
					</FormGroup>
					<FormGroup>
						<TextField id="service-fulfillment" label="Sonstige Dienstleistung" variant="outlined" helperText='Fulfillment-Manager' inputRef={otherServiceRef} />
					</FormGroup>
					<FormGroup>
						<TextField id="unannounced-delivery" label="Unangemeldete Anlieferung" variant="outlined" inputRef={unannouncedDeliveryRef}/>
					</FormGroup>
					
					<List>
						<ListItem alignItems="center" className="checkbox_and_text">
							<FormControlLabel control={
								<Checkbox 
									checked={assemblyCheckBox} 
									name="assemblyCheckBox" 
									onChange={handleCheckboxChange} 
									inputRef={assemblyCheckRef} 
								/>} label="Konfektionierung" />
							<TextField 
								fullWidth 
								inputRef={assemblyInputRef}
							/>
						</ListItem>
					</List>
					<List>
						<ListItem alignItems="center" className="checkbox_and_text">
							<FormControlLabel control={
								<Checkbox 
									checked={returnProcessingCheckBox} 
									name="returnProcessingCheckBox" 
									onChange={handleCheckboxChange} 
									inputRef={returnProcessingCheckRef} 
								/>} label="Retourenbearbeitung (pro Retoure)" />
							<TextField 
								fullWidth 
								inputRef={returnProcessingInputRef}
							/>
						</ListItem>
					</List>
					<List>
						<ListItem alignItems="center" className="checkbox_and_text">
						<FormControlLabel control={
							<Checkbox 
								checked={returnProcessingPerItemCheckBox} 
								name="returnProcessingPerItemCheckBox" 
								onChange={handleCheckboxChange} 
								inputRef={returnProcessingPerItemCheckRef} 
							/>} label="Retourenbearbeitung (pro darin enthaltenem Artikel)" />
							<TextField 
								fullWidth 
								inputRef={returnProcessingPerItemInputRef}
							/>
						</ListItem>
					</List>
					<List>
						<ListItem alignItems="center" className="checkbox_and_text">
							<FormControlLabel control={
								<Checkbox 
									checked={pickOnOrderCheckBox} 
									name="pickOnOrderCheckBox" 
									onChange={handleCheckboxChange} 
									inputRef={pickOnOrderCheckRef} 
								/>} label="1. Pick im Auftrag" />
							<TextField 
								fullWidth 
								inputRef={pickOnOrderInputRef}
							/>
						</ListItem>
					</List>
					<List>
						<ListItem alignItems="center" className="checkbox_and_text">
							<FormControlLabel control={
								<Checkbox 
									checked={anotherPickSameCheckBox} 
									name="anotherPickSameCheckBox" 
									onChange={handleCheckboxChange} 
									inputRef={anotherPickSameCheckRef} 
								/>} label="Weiterer Pick selber Auftrag (gleicher Artikel)" />
							<TextField 
								fullWidth 
								inputRef={anotherPickSameInputRef}
							/>
						</ListItem>
					</List>
					<List>
						<ListItem alignItems="center" className="checkbox_and_text">
							<FormControlLabel control={
								<Checkbox 
									checked={anotherPickDiffCheckBox} 
									name="anotherPickDiffCheckBox" 
									onChange={handleCheckboxChange} 
									inputRef={anotherPickDiffCheckRef} 
								/>} label="Weiterer Pick selber Auftrag (ungleicher Artikel)" />
							<TextField 
								fullWidth 
								inputRef={anotherPickDiffInputRef}
							/>
						</ListItem>
					</List>
					<List>
						<ListItem alignItems="center" className="checkbox_and_text">
							<FormControlLabel control={
								<Checkbox 
									checked={boxSmallBPSCheckBox} 
									name="boxSmallBPSCheckBox" 
									onChange={handleCheckboxChange} 
									inputRef={boxSmallBPSCheckRef} 
								/>} label="Kleine Kartonage von BPS (bis **x**x**cm)" />
							<TextField 
								fullWidth 
								inputRef={boxSmallBPSInputRef}
							/>
						</ListItem>
					</List>
					<List>
						<ListItem alignItems="center" className="checkbox_and_text">
							<FormControlLabel control={
								<Checkbox 
									checked={boxMediumBPSCheckBox}
									name="boxMediumBPSCheckBox" 
									onChange={handleCheckboxChange} 
									inputRef={boxMediumBPSCheckRef} 
								/>} label="Medium-Kartonage von BPS (bis **x**x**cm)" />
							<TextField 
								fullWidth 
								inputRef={boxMediumBPSInputRef}
							/>
						</ListItem>
					</List>
					<List>
						<ListItem alignItems="center" className="checkbox_and_text">
							<FormControlLabel control={
								<Checkbox 
									checked={boxMidLargeBPSCheckBox} 
									name="boxMidLargeBPSCheckBox"
									onChange={handleCheckboxChange} 
									inputRef={boxMidLargeBPSCheckRef} />} label="Medium-Große Kartonage von BPS (bis **x**x**cm)" />
							<TextField 
								fullWidth 
								inputRef={boxMidLargeBPSInputRef}
							/>
						</ListItem>
					</List>
					<List>
						<ListItem alignItems="center" className="checkbox_and_text">
							<FormControlLabel control={
								<Checkbox 
									checked={boxLargeBPSCheckbox} 
									name="boxLargeBPSCheckbox" 
									onChange={handleCheckboxChange} 
									inputRef={boxLargeBPSCheckRef} 
								/>} label="Große Kartonage von BPS (bis **x**x**cm)" />
							<TextField 
								fullWidth 
								inputRef={boxLargeBPSInputRef}
							/>
						</ListItem>
					</List>
					<List>
						<ListItem alignItems="center" className="checkbox_and_text">
							<FormControlLabel control={
								<Checkbox 
									checked={boxXXLBPSCheckbox} 
									name="boxXXLBPSCheckbox" 
									onChange={handleCheckboxChange} 
									inputRef={boxXXLBPSCheckRef} 
								/>} label="XXL Kartonage von BPS (bis **x**x**cm)" />
							<TextField 
								fullWidth 
								inputRef={boxXXLBPSInputRef}
							/>
						</ListItem>
					</List>
								

					<Alert severity="info" style={{ width: '100%', margin: "1rem 0 0.5rem 0" }}>Versandpreise: <b>Siehe Bereich "Versandpreise"</b></Alert>
					
					<div style={{display: 'flex', justifyContent: 'space-start', width: '100%', marginTop: '1.5rem'}}>
						<Button variant="contained" onClick={submitHandler}>Submit</Button>
					</div>
				</Box>
				</section>
			</main>
		</>
    );
}

export default PDFCreationForm;