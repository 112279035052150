import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Link, Image, pdf, Font } from '@react-pdf/renderer';

import MainNavigation from "../components/MainNavigation";
import pageOneHeader from '../assets/images/pdf/page1-head.jpg';
import pageOneFooter from '../assets/images/pdf/page1-footer.jpg';
import pageOneTitle from '../assets/images/pdf/page1-title.png';
import pageTwoHeader from '../assets/images/pdf/page2-head.jpg';
import pageThree from '../assets/images/pdf/page3.jpg';

/* importing fonts */
import FontPoppinsRegular from '../assets/fonts/Poppins-Regular.ttf';
import FontPoppinsSemiBold from '../assets/fonts/Poppins-SemiBold.ttf';
import FontPoppinsBold from '../assets/fonts/Poppins-Bold.ttf';

Font.register({
	family: 'Poppins',
	fonts: [
		{ src: FontPoppinsRegular },
		{
			src: FontPoppinsSemiBold, 
			fontWeight: 600
		},
		{
			src: FontPoppinsBold,
			fontWeight: 700
		}
	]
});

const styles = StyleSheet.create({
	viewer: {
		width: window.innerWidth,
		height: window.innerHeight,
	},
	page: {
		margin: 0,
		padding: 0,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		fontFamily: 'Poppins'
	},
	pageMid: {
		margin: 0,
		padding: 0,
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	image: {
		width: 100
	},
	footerlink: { 
		display: 'flex',
		marginLeft: '20pt',
		color: 'white',
		textDecoration: 'none'
	},
	midfooter: {
		backgroundColor: 'rgba(9,43,105,0.5)',
		height: 60,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '12pt',
		textAlign: 'center',
		color: 'white',
		width: '100%',
		position: 'absolute',
		bottom: 0
	},
	fieldsSection: {
		padding: '0 20pt',
		alignSelf: 'flex-start',
		alignContent: 'flex-start',
		fontSize: '11pt',
		display: 'flex',
		width: '50%'
	},
	fieldBox: {
		display: 'flex',
		flexFlow: 'column',
		width: '50%'
	},
	recordTitle:{
		fontSize: '11pt',
		paddingBottom: '1pt',
		color: '#666699'
	},
	recordContent: {
		fontSize: '11pt',
		paddingBottom: '8pt'
	},
	personalText: {
		fontSize: '12pt',
		padding: '7pt 20pt',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap'
	},
	listTitle: {
		color: '#213768',
		textDecoration: 'underline',
		fontSize: '11pt',
		fontFamily: 'Poppins',
		fontWeight: 700,
		padding: '0 20pt',
		marginBottom: '-6pt',
		marginTop: '6pt'
	},
	listItemBull: {
		display: 'flex',
		width: '1%',
		minWidth: '2pt',
		marginLeft: '3%'
	},
	listItemContent: {
		display: 'flex',
		width: '96%'
	}
});


const PDFPreview = () => {
	const navigate = useNavigate();
    const getPdfData = localStorage.getItem('pdfData');

	useEffect(()=>{
		if(!getPdfData) navigate('/createpdf');
	},[getPdfData]);

    const pdfData = JSON.parse(getPdfData);
	let companyName = " ";
	if(pdfData.customerCompany) { companyName = pdfData.customerCompany; }

    const [readyToRend,setReadyToRend] = useState(false);
    let today = new Date().toLocaleDateString();
	useEffect(() => {
		setReadyToRend(true);
	},[]);

    return (
        <>
			<MainNavigation/>
			{readyToRend===true && (
			<PDFViewer style={styles.viewer}>
				{/* Start of the document*/}
				<Document title={`BPS Fulfillment - Angebot - ${companyName} ${today}`}>
					<Page size="A4" orientation="portrait" style={styles.page}>
						<View style={styles.section}>
							<Image 
								style={{width: '100%'}} 
								src={pageOneHeader}
							/>
						</View>
						<View style={styles.section}>
							<Image 
								style={{width: '400', margin: '0 auto 20px auto'}} 
								src={pageOneTitle}
							/>
						</View>
						<View>
							<Image style={{width: '100%'}} src={pageOneFooter}/>
						</View>
					</Page>
					<Page size="A4" orientation="portrait" style={styles.pageMid}>
						<View>
							<Image style={{width: '100%'}} src={pageTwoHeader}/>
						</View>
						<View style={{display: 'flex', flexDirection: 'row', paddingTop: '16pt'}}>
						<View style={styles.fieldsSection}>
							{pdfData.editorName.length > 0 && 
								<><Text style={styles.recordTitle}>Bearbeiter:</Text>
								<Text style={styles.recordContent}>{pdfData.editorName}</Text></>
							}
							
							{pdfData.editorPhone.length > 0 && 
								<><Text style={styles.recordTitle}>Bearbeiter Telefon:</Text>
								<Text style={styles.recordContent}>{pdfData.editorPhone}</Text></>
							}
							
							{pdfData.editorEmail.length > 0 && 
								<><Text style={styles.recordTitle}>Bearbeiter E-mail:</Text>
								<Text style={styles.recordContent}>{pdfData.editorEmail}</Text></>
							}

							{pdfData.customerTitle.length > 0 && 
								<><Text style={styles.recordTitle}>Kunde Title:</Text>
								<Text style={styles.recordContent}>{pdfData.customerTitle}</Text></>
							}
							
							{pdfData.customerContactPerson.length > 0 && 
								<><Text style={styles.recordTitle}>Kunde Ansprechpartner:</Text>
								<Text style={styles.recordContent}>{pdfData.customerContactPerson}</Text></>
							}
							
							{pdfData.customerCompany.length > 0 && 
								<><Text style={styles.recordTitle}>Kunde Firmenname:</Text>
								<Text style={styles.recordContent}>{pdfData.customerCompany}</Text></>
							}

							{pdfData.customerCity.length > 0 && 
								<><Text style={styles.recordTitle}>Kunde Anschrift:</Text>
								<Text style={styles.recordContent}>
									{pdfData.customerStreet} {pdfData.customerStrNumber}, 
									{pdfData.customerZip} {pdfData.customerCity}, 
									{pdfData.customerCountry}
								</Text></>
							}

							{pdfData.offerFor.length > 0 && 
								<><Text style={styles.recordTitle}>Angebot erstellen für:</Text>
								<Text style={styles.recordContent}>{pdfData.offerFor}</Text></>
							}

							{pdfData.skuNumber.length > 0 && 
								<><Text style={styles.recordTitle}>Anzahl SKU:</Text>
								<Text style={styles.recordContent}>{pdfData.skuNumber}</Text></>
							}

							{pdfData.shippingOrders.length > 0 && 
								<><Text style={styles.recordTitle}>Versandaufträge (monatl.):</Text>
								<Text style={styles.recordContent}>{pdfData.shippingOrders}</Text></>
							}
							
							{pdfData.reqStorageSpace.length > 0 && 
								<><Text style={styles.recordTitle}>Benötigte Lagerfläche (m³):</Text>
								<Text style={styles.recordContent}>{pdfData.reqStorageSpace}</Text></>
							}

							{pdfData.erpConnection.length > 0 && 
								<><Text style={styles.recordTitle}>Anbindung (ERP-/Shopsystem):</Text>
								<Text style={styles.recordContent}>{pdfData.erpConnection}</Text></>
							}

							{pdfData.goodsReceiptProPalette.length > 0 && 
								<><Text style={styles.recordTitle}>Warenannahme (pro Palette):</Text>
								<Text style={styles.recordContent}>{pdfData.goodsReceiptProPalette}</Text></>
							}
							
							{pdfData.goodsReceiptProPackage.length > 0 && 
								<><Text style={styles.recordTitle}>Warenannahme (pro Paket):</Text>
								<Text style={styles.recordContent}>{pdfData.goodsReceiptProPackage}</Text></>
							}

							{pdfData.dischargeAndStorage.length > 0 && 
								<><Text style={styles.recordTitle}>Entladung +  EInlagerung (pro Person & Stunde):</Text>
								<Text style={styles.recordContent}>{pdfData.dischargeAndStorage}</Text></>
							}
						</View>
						<View style={styles.fieldsSection}>

							{pdfData.otherServices.length > 0 && 
								<><Text style={styles.recordTitle}>Sonstige Dienstleistungen (pro Lagerist & Stunde):</Text>
								<Text style={styles.recordContent}>{pdfData.otherServices}</Text></>
							}

							{pdfData.otherService.length > 0 && 
								<><Text style={styles.recordTitle}>Sonstige Dienstleistung (Fulfillment-Manager):</Text>
								<Text style={styles.recordContent}>{pdfData.otherService}</Text></>
							}

							{pdfData.unannouncedDelivery.length > 0 && 
								<><Text style={styles.recordTitle}>Unangemeldete Anlieferung:</Text>
								<Text style={styles.recordContent}>{pdfData.unannouncedDelivery}</Text></>
							}
							
							{pdfData.assemblyCheck && 
								<><Text style={styles.recordTitle}>Konfektionierung:</Text>
								<Text style={styles.recordContent}>{pdfData.assemblyInput}</Text></>
							}

							{pdfData.returnProcessingCheck && 
								<><Text style={styles.recordTitle}>Retourenbearbeitung (pro Retoure):</Text>
								<Text style={styles.recordContent}>{pdfData.returnProcessingInput}</Text></>
							}

							{pdfData.returnProcessingPerItemCheck && 
								<><Text style={styles.recordTitle}>Retourenbearbeitung (pro darin enthaltenem Artikel):</Text>
								<Text style={styles.recordContent}>{pdfData.returnProcessingPerItemInput}</Text></>
							}

							{pdfData.pickOnOrderCheck && 
								<><Text style={styles.recordTitle}>1. Pick im Auftrag:</Text>
								<Text style={styles.recordContent}>{pdfData.pickOnOrderInput}</Text></>
							}

							{pdfData.anotherPickSameCheck && 
								<><Text style={styles.recordTitle}>Weiterer Pick selber Auftrag (gleicher Artikel):</Text>
								<Text style={styles.recordContent}>{pdfData.anotherPickSameInput}</Text></>
							}

							{pdfData.anotherPickDiffCheck && 
								<><Text style={styles.recordTitle}>Weiterer Pick selber Auftrag (ungleicher Artikel):</Text>
								<Text style={styles.recordContent}>{pdfData.anotherPickDiffInput}</Text></>
							}

							{pdfData.boxSmallBPSCheck && 
								<><Text style={styles.recordTitle}>Kleine Kartonage von BPS (bis **x**x**cm):</Text>
								<Text style={styles.recordContent}>{pdfData.boxSmallBPSInput}</Text></>
							}

							{pdfData.boxMediumBPSCheck && 
								<><Text style={styles.recordTitle}>Medium-Kartonage von BPS (bis **x**x**cm):</Text>
								<Text style={styles.recordContent}>{pdfData.boxMediumBPSInput}</Text></>
							}

							{pdfData.boxMidLargeBPSCheck && 
								<><Text style={styles.recordTitle}>Medium-Große Kartonage von BPS (bis **x**x**cm):</Text>
								<Text style={styles.recordContent}>{pdfData.boxMidLargeBPSInput}</Text></>
							}

							{pdfData.boxLargeBPSCheck && 
								<><Text style={styles.recordTitle}>Große Kartonage von BPS (bis **x**x**cm):</Text>
								<Text style={styles.recordContent}>{pdfData.boxLargeBPSInput}</Text></>
							}

							{pdfData.boxXXLBPSCheck && 
								<><Text style={styles.recordTitle}>XXL Kartonage von BPS (bis **x**x**cm):</Text>
								<Text style={styles.recordContent}>{pdfData.boxXXLBPSInput}</Text></>
							}
							
						</View></View>
						<View style={styles.midfooter}>
							<Text>© BUCHHORN PARCEL SERVICE GMBH |  
							<Link style={styles.footerlink} src={'https://bps-germany.com'}><Text> bps-germany.com</Text></Link></Text>
						</View>
					</Page>
					
					<Page size="A4" orientation="portrait" style={styles.pageMid}>
						<View>
							<Image style={{width: '100%'}} src={pageTwoHeader}/>
						</View>
						<View style={styles.personalText}>
							<Text style={{paddingTop: '16pt'}}>Sehr geehrte {pdfData.customerTitle} {pdfData.customerContactPerson},</Text>
						</View>
						<View style={styles.personalText}>
							<Text>
								vielen Dank für Ihre {pdfData.offerFor}-Anfrage. Gerne erstellen wir Ihnen hiermit ein erstes unverbindliches Angebot.<br/>
								Bevor wir uns zusammen in die Zahlen stürzen, wollen wir Ihnen gerne noch einen Eindruck von unserem Unternehmen vermitteln.
							</Text>
						</View>
						<View style={styles.personalText}>
							<Text>
								Mit derzeit knapp 10.000m² Lagerfläche bietet BPS Ihnen und Ihrer Ware eine sichere Umgebung um Artikel schnell von Ihnen zu Ihren Kunden zu befördern. Dank einer über die Jahrzehnte stetig wachsenden und engen Partnerschaft mit Versanddienstleistern wie GLS oder DPD können wir Ihnen nicht nur die reine Fulfillment-Dienstleistung unschlagbar günstig anbieten. Auch der Paketversand über BPS findet zu absolut konkurrenzfähigen Preisen statt. Dies verschafft Ihnen entweder einen Wettbewerbsvorteil oder mehr Marge - diese Entscheidung liegt bei Ihnen.<br/>
								Ohne eine extra Express-Dienstleistung zu buchen, haben Ihre Pakete innerhalb Deutschlands zu 95% eine durchschnittliche Laufzeit von nur einem Tag. 
							</Text>
						</View>
						<View style={styles.personalText}>
							<Text>
								Durch die Direkteinspeisung in das österreichische Paketnetz und dem dafür günstigen Standort unweit der Grenze, gelangen Ihre Pakete von unseren Lägern schnell und günstig auch zu unseren südlichen Nachbarn. Mit einer Laufzeit von im Schnitt 85% am nächsten Werktag erreicht Ihre Ware auch Kunden in Österreich sehr zügig. Dies wirkt sich nicht selten auch auf die Bewertungen aus ==> *****
							</Text>
						</View>
						<View style={styles.listTitle}>
							<Text>Ihre Vorteile einer Zusammenarbeit mit BPS:</Text>
						</View>
						<View style={styles.personalText}>
							<Text style={styles.listItemBull}>•</Text>
							<Text style={styles.listItemContent}>effiziente operative Prozesse</Text>
							<Text style={styles.listItemBull}>•</Text>
							<Text style={styles.listItemContent}>schneller und kostengünstiger</Text>
							<Text style={styles.listItemBull}>•</Text>
							<Text style={styles.listItemContent}>weniger Lohn- und Lohnnebenkosten</Text>
							<Text style={styles.listItemBull}>•</Text>
							<Text style={styles.listItemContent}>geringere Lager-, Bestands-, Auftragsabwicklungs- und Transportkosten</Text>
							<Text style={styles.listItemBull}>•</Text>
							<Text style={styles.listItemContent}>alle Kosten passen sich flexibel Ihrem Geschäftsverlauf an</Text>
							<Text style={styles.listItemBull}>•</Text>
							<Text style={styles.listItemContent}>verlässliche Lieferzeiten, sorgfältige Behandlung Ihrer Ware</Text>
							<Text style={styles.listItemBull}>•</Text>
							<Text style={styles.listItemContent}>positive Gestaltung der Kundenbeziehungen und -zufriedenheit</Text>
							<Text style={styles.listItemBull}>•</Text>
							<Text style={styles.listItemContent}>weniger Retouren</Text>
						</View>
						<View style={styles.listTitle}>
							<Text>Ihr Nutzen:</Text>
						</View>
						<View style={styles.personalText}>
							<Text style={styles.listItemBull}>•</Text>
							<Text style={styles.listItemContent}>Konzentration auf wichtige unternehmerische Kernkompetenzen</Text>
							<Text style={styles.listItemBull}>•</Text>
							<Text style={styles.listItemContent}>Absetzten von den Wettbewerbern</Text>
							<Text style={styles.listItemBull}>•</Text>
							<Text style={styles.listItemContent}>Steigerung der Marktpräsenz und Wettbewerbsfähigkeit</Text>
							<Text style={styles.listItemBull}>•</Text>
							<Text style={styles.listItemContent}>höhere Wertschöpfung</Text>
						</View>
						<View style={styles.personalText}>
							<Text style={{marginTop: '10pt'}}>
								Ich freue mich sehr, Sie in kommenden Gesprächen weiterhin beraten zu dürfen.
							</Text>
						</View>
						<View style={styles.personalText}>
							<Text style={{width: '100%'}}>
								Bis dahin die besten Grüße,
							</Text>
							<Text style={{width: '100%', paddingTop: '2pt'}}>
								{pdfData.editorName}
							</Text>
						</View>
						<View style={styles.midfooter}>
							<Text>© BUCHHORN PARCEL SERVICE GMBH |  
							<Link style={styles.footerlink} src={'https://bps-germany.com'}><Text> bps-germany.com</Text></Link></Text>
						</View>
					</Page>

					<Page size="A4" orientation="portrait" style={styles.page}>
						<View>
							<Image style={{width: '100%', height: 841.8}} src={pageThree} />
						</View>
					</Page>
				</Document>
			</PDFViewer>
		)}
		</>
	);
}

export default PDFPreview;