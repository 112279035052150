import { toast } from 'react-toastify';
import ShowMessage from './ShowMessage';

const displayMsg = (props) => {
    let createToast = toast.info;
    if(props.type === 'success') { createToast = toast.success; }
    if(props.type === 'warning') { createToast = toast.warning; }
    if(props.type === 'error') { createToast = toast.error; }
    createToast(<ShowMessage message={props.message}/>, {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored"
      });
}
export default displayMsg;