import { Route, Routes, Navigate } from "react-router-dom";

import PDFCreationForm from './pages/PDFCreationForm';
import PDFPreview from './pages/PDFPreview';

import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import "./assets/css/Global.scss";


const App = () => {
    return (
        <StyledEngineProvider injectFirst>
            <Routes>
                <Route path='/' element={<Navigate to='/createpdf' replace />}/>
                <Route path='/createpdf' element={<PDFCreationForm/>}/>
                <Route path='/pdfpreview' element={<PDFPreview/>}/>
                <Route path='*' element={<Navigate to='/createpdf' replace />} />
            </Routes>
        </StyledEngineProvider>
    );
}

export default App;
